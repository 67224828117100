/* eslint-disable node/prefer-global/process */
export default defineNuxtRouteMiddleware(async (from, to) => {
    const firebaseUser = await ensureFirebaseUser(true)
    if (!firebaseUser)
        return navigateTo('/problems')

    await ensureApolloInitialized(firebaseUser)

    if (process.client) {
        const userResult = await ensureCiviqaUserOnClient({ requireEmailVerified: true })
        if (userResult.notRegistered) {
            return navigateTo('/signup?redirect=' + to.fullPath)
        }
        if (userResult.notEmailVerified) {
            return navigateTo('/verify-email')
        }
        if (userResult.message) {
            return navigateTo('/problems?message=' + userResult.message)
        }
        if (!userResult.civiqaUser) {
            return navigateTo('/problems')
        }
    }
})
